import React, { useEffect, useState } from "react";
import "./merry.css";
export default function Merry() {

  return (
    <>
      {/* <style>{generateDynamicStyles()}</style> */}
      <div
        className="a3d"
        style={{ "--n-arms": 2, "--n-stars": 100, "--n-loops": 4 }}
      >
        {/* <div
          className="🌟"
          style={{ "--rox": 0.13, "--roy": -0.04, "--roz": 0.18, "--sf": 0.98 }}
        ></div>{" "}
        <div
          className="🌟"
          style={{ "--rox": 0.13, "--roy": -0.04, "--roz": 0.18, "--sf": 0.98 }}
        ></div>{" "}
        <div
          className="🌟"
          style={{ "--rox": 0.13, "--roy": -0.04, "--roz": 0.18, "--sf": 0.98 }}
        ></div> */}
        <div>
          <div
            className="🌟"
            style={{
              "--rox": "0.13",
              "--roy": "-0.04",
              "--roz": "0.18",
              "--sf": "0.98",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.16",
              "--roy": "0.1",
              "--roz": "0.12",
              "--sf": "0.76",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.05",
              "--roy": "-0.15",
              "--roz": "0.15",
              "--sf": "0.59",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.15",
              "--roy": "0.08",
              "--roz": "0.05",
              "--sf": "0.54",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.04",
              "--roy": "0.11",
              "--roz": "0.1",
              "--sf": "0.82",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "0.17",
              "--roz": "0.13",
              "--sf": "0.73",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.13",
              "--roy": "-0.06",
              "--roz": "-0.1",
              "--sf": "0.61",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.03",
              "--roy": "-0.08",
              "--roz": "0.08",
              "--sf": "0.99",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.18",
              "--roy": "-0.09",
              "--roz": "-0.08",
              "--sf": "0.65",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.1",
              "--roy": "0.07",
              "--roz": "-0.18",
              "--sf": "0.85",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.05",
              "--roy": "-0.05",
              "--roz": "-0.14",
              "--sf": "0.69",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.09",
              "--roy": "-0.14",
              "--roz": "-0.13",
              "--sf": "0.66",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.18",
              "--roy": "-0.16",
              "--roz": "0.11",
              "--sf": "0.8",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.02",
              "--roy": "-0.06",
              "--roz": "-0.12",
              "--sf": "0.98",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "-0.01",
              "--roz": "-0.06",
              "--sf": "0.94",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.17",
              "--roy": "0",
              "--roz": "0.16",
              "--sf": "0.55",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.15",
              "--roy": "-0.06",
              "--roz": "0.14",
              "--sf": "0.91",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.03",
              "--roy": "-0.18",
              "--roz": "0.16",
              "--sf": "0.87",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.1",
              "--roy": "0.14",
              "--roz": "0.12",
              "--sf": "0.74",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.09",
              "--roy": "-0.03",
              "--roz": "-0.06",
              "--sf": "0.82",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.09",
              "--roy": "0.07",
              "--roz": "0.17",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.16",
              "--roy": "-0.02",
              "--roz": "-0.1",
              "--sf": "0.85",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.05",
              "--roy": "0.17",
              "--roz": "0.15",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.07",
              "--roy": "-0.09",
              "--roz": "0.08",
              "--sf": "0.8",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.02",
              "--roy": "-0.14",
              "--roz": "0.07",
              "--sf": "0.72",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.19",
              "--roy": "-0.15",
              "--roz": "0.07",
              "--sf": "0.9",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.01",
              "--roy": "-0.18",
              "--roz": "0.09",
              "--sf": "0.62",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.03",
              "--roy": "0.02",
              "--roz": "0.13",
              "--sf": "0.52",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.04",
              "--roy": "-0.18",
              "--roz": "-0.02",
              "--sf": "0.56",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.07",
              "--roy": "-0.19",
              "--roz": "0.03",
              "--sf": "0.69",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "-0.13",
              "--roz": "-0.11",
              "--sf": "0.82",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.15",
              "--roy": "-0.12",
              "--roz": "0.16",
              "--sf": "0.6",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.08",
              "--roy": "0.07",
              "--roz": "-0.09",
              "--sf": "0.61",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.08",
              "--roy": "0",
              "--roz": "0.04",
              "--sf": "0.68",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.06",
              "--roz": "0.17",
              "--sf": "0.78",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.06",
              "--roy": "0.17",
              "--roz": "-0.15",
              "--sf": "0.64",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.1",
              "--roy": "-0.02",
              "--roz": "0.15",
              "--sf": "0.72",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.03",
              "--roy": "0.08",
              "--roz": "-0.14",
              "--sf": "0.98",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.09",
              "--roy": "0.12",
              "--roz": "-0.15",
              "--sf": "0.84",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.05",
              "--roy": "-0.17",
              "--roz": "0",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.05",
              "--roy": "-0.19",
              "--roz": "0.01",
              "--sf": "0.95",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.18",
              "--roy": "0.05",
              "--roz": "-0.18",
              "--sf": "0.5",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.01",
              "--roy": "0.11",
              "--roz": "-0.08",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.04",
              "--roy": "-0.02",
              "--roz": "0.03",
              "--sf": "0.97",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.11",
              "--roy": "0.01",
              "--roz": "0.13",
              "--sf": "0.62",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.13",
              "--roy": "-0.11",
              "--roz": "-0.1",
              "--sf": "0.59",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.04",
              "--roy": "-0.05",
              "--roz": "0.09",
              "--sf": "0.74",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.14",
              "--roy": "-0.04",
              "--roz": "-0.06",
              "--sf": "0.76",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.17",
              "--roy": "-0.06",
              "--roz": "0.05",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.07",
              "--roy": "0.04",
              "--roz": "0",
              "--sf": "0.67",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.03",
              "--roy": "-0.01",
              "--roz": "-0.12",
              "--sf": "0.83",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.04",
              "--roy": "-0.04",
              "--roz": "-0.04",
              "--sf": "0.97",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.09",
              "--roy": "0.14",
              "--roz": "0.07",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.03",
              "--roy": "-0.19",
              "--roz": "-0.18",
              "--sf": "0.87",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.11",
              "--roy": "-0.06",
              "--roz": "0.05",
              "--sf": "0.84",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.1",
              "--roy": "0.16",
              "--roz": "-0.16",
              "--sf": "0.55",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.05",
              "--roy": "0",
              "--roz": "-0.18",
              "--sf": "0.52",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.2",
              "--roy": "-0.03",
              "--roz": "0.14",
              "--sf": "0.57",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.14",
              "--roy": "-0.12",
              "--roz": "0.01",
              "--sf": "0.93",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "0.17",
              "--roz": "0.15",
              "--sf": "0.96",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.16",
              "--roy": "-0.04",
              "--roz": "0.05",
              "--sf": "0.79",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.18",
              "--roy": "0.14",
              "--roz": "0.19",
              "--sf": "0.84",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0",
              "--roy": "0.03",
              "--roz": "0.09",
              "--sf": "0.93",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.16",
              "--roy": "0.08",
              "--roz": "0.2",
              "--sf": "0.81",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.03",
              "--roy": "-0.18",
              "--roz": "0.13",
              "--sf": "0.97",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.11",
              "--roy": "-0.15",
              "--roz": "-0.02",
              "--sf": "0.68",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "0.06",
              "--roz": "0.16",
              "--sf": "0.65",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.19",
              "--roy": "-0.03",
              "--roz": "-0.08",
              "--sf": "0.64",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.2",
              "--roy": "0.16",
              "--roz": "0.18",
              "--sf": "0.86",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.15",
              "--roy": "0.19",
              "--roz": "0.1",
              "--sf": "0.65",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0",
              "--roy": "-0.02",
              "--roz": "-0.07",
              "--sf": "0.96",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.19",
              "--roy": "-0.07",
              "--roz": "0.17",
              "--sf": "0.83",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.17",
              "--roy": "-0.01",
              "--roz": "0.03",
              "--sf": "0.83",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.01",
              "--roy": "0.05",
              "--roz": "0.06",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.17",
              "--roy": "0.09",
              "--roz": "0.05",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.08",
              "--roy": "-0.02",
              "--roz": "0.12",
              "--sf": "0.91",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0",
              "--roy": "0.11",
              "--roz": "0.06",
              "--sf": "0.66",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.01",
              "--roy": "-0.1",
              "--roz": "0.02",
              "--sf": "0.69",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.18",
              "--roz": "0.16",
              "--sf": "0.83",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.09",
              "--roy": "-0.09",
              "--roz": "-0.04",
              "--sf": "0.87",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.18",
              "--roy": "-0.06",
              "--roz": "-0.2",
              "--sf": "0.55",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.1",
              "--roy": "-0.1",
              "--roz": "0.09",
              "--sf": "0.85",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.08",
              "--roy": "0",
              "--roz": "-0.1",
              "--sf": "0.57",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.03",
              "--roy": "0.16",
              "--roz": "-0.08",
              "--sf": "0.51",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.15",
              "--roy": "0",
              "--roz": "0.14",
              "--sf": "0.75",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.04",
              "--roy": "0.1",
              "--roz": "0.04",
              "--sf": "0.6",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.16",
              "--roy": "0.17",
              "--roz": "0.03",
              "--sf": "0.66",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.02",
              "--roy": "-0.19",
              "--roz": "0.15",
              "--sf": "0.86",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.12",
              "--roy": "0.01",
              "--roz": "0.08",
              "--sf": "0.73",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.05",
              "--roy": "-0.07",
              "--roz": "0.09",
              "--sf": "0.97",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.05",
              "--roy": "0.02",
              "--roz": "-0.05",
              "--sf": "0.81",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0",
              "--roy": "-0.15",
              "--roz": "0.12",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.04",
              "--roy": "-0.12",
              "--roz": "0.09",
              "--sf": "0.87",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.15",
              "--roy": "0.01",
              "--roz": "0.04",
              "--sf": "0.77",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0",
              "--roy": "0.16",
              "--roz": "0.09",
              "--sf": "0.83",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.05",
              "--roy": "0.06",
              "--roz": "-0.13",
              "--sf": "0.57",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.08",
              "--roy": "0.19",
              "--roz": "-0.18",
              "--sf": "0.76",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.19",
              "--roy": "0.14",
              "--roz": "-0.1",
              "--sf": "0.88",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.03",
              "--roy": "-0.07",
              "--roz": "0.06",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.17",
              "--roy": "-0.13",
              "--roz": "-0.04",
              "--sf": "0.61",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.1",
              "--roy": "0.01",
              "--roz": "-0.11",
              "--sf": "0.61",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.09",
              "--roy": "0.09",
              "--roz": "0.06",
              "--sf": "0.86",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.08",
              "--roy": "0.1",
              "--roz": "0.12",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.06",
              "--roy": "-0.08",
              "--roz": "0",
              "--sf": "0.81",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.16",
              "--roy": "0.09",
              "--roz": "-0.14",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.18",
              "--roy": "-0.05",
              "--roz": "0.16",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "-0.19",
              "--roz": "0.12",
              "--sf": "0.69",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.06",
              "--roy": "-0.01",
              "--roz": "0.01",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.18",
              "--roy": "-0.05",
              "--roz": "-0.05",
              "--sf": "0.63",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.13",
              "--roy": "0.2",
              "--roz": "-0.1",
              "--sf": "0.58",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.06",
              "--roy": "0",
              "--roz": "0.03",
              "--sf": "0.63",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.16",
              "--roy": "-0.19",
              "--roz": "0.17",
              "--sf": "0.74",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.02",
              "--roy": "-0.14",
              "--roz": "-0.17",
              "--sf": "0.59",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.15",
              "--roy": "0.19",
              "--roz": "-0.17",
              "--sf": "0.89",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.17",
              "--roy": "0.17",
              "--roz": "0.16",
              "--sf": "0.75",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.08",
              "--roy": "-0.17",
              "--roz": "0.09",
              "--sf": "0.8",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.19",
              "--roy": "-0.19",
              "--roz": "-0.02",
              "--sf": "0.74",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.1",
              "--roy": "0.02",
              "--roz": "0",
              "--sf": "0.76",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.02",
              "--roy": "0.06",
              "--roz": "-0.11",
              "--sf": "0.53",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.05",
              "--roy": "-0.18",
              "--roz": "0.03",
              "--sf": "0.99",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.15",
              "--roy": "0.06",
              "--roz": "0.01",
              "--sf": "0.61",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.2",
              "--roy": "0.1",
              "--roz": "0.11",
              "--sf": "0.77",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.14",
              "--roy": "0.09",
              "--roz": "0.18",
              "--sf": "0.57",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.01",
              "--roy": "-0.08",
              "--roz": "0.16",
              "--sf": "0.94",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.01",
              "--roy": "-0.06",
              "--roz": "-0.19",
              "--sf": "0.98",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.16",
              "--roy": "-0.16",
              "--roz": "-0.2",
              "--sf": "0.63",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.12",
              "--roz": "0.01",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "-0.19",
              "--roz": "-0.13",
              "--sf": "0.67",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.2",
              "--roz": "0.09",
              "--sf": "0.51",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.15",
              "--roy": "0",
              "--roz": "0.16",
              "--sf": "0.63",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "0.17",
              "--roz": "-0.13",
              "--sf": "0.84",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.19",
              "--roy": "-0.05",
              "--roz": "0.1",
              "--sf": "0.59",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.1",
              "--roy": "-0.01",
              "--roz": "0.2",
              "--sf": "0.93",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.15",
              "--roy": "-0.12",
              "--roz": "-0.03",
              "--sf": "0.52",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.09",
              "--roy": "-0.13",
              "--roz": "0.05",
              "--sf": "0.95",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.09",
              "--roz": "-0.15",
              "--sf": "0.94",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.06",
              "--roy": "-0.14",
              "--roz": "-0.04",
              "--sf": "0.98",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.02",
              "--roy": "0.02",
              "--roz": "0.15",
              "--sf": "0.85",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.1",
              "--roy": "0.11",
              "--roz": "0.01",
              "--sf": "0.52",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.03",
              "--roy": "0.16",
              "--roz": "0.11",
              "--sf": "0.63",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.16",
              "--roy": "-0.01",
              "--roz": "0.15",
              "--sf": "0.85",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.03",
              "--roy": "-0.07",
              "--roz": "0.17",
              "--sf": "0.82",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.12",
              "--roy": "-0.07",
              "--roz": "0.17",
              "--sf": "0.62",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "0.18",
              "--roz": "0.03",
              "--sf": "0.64",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.06",
              "--roy": "0.2",
              "--roz": "0.15",
              "--sf": "0.82",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.06",
              "--roy": "0.02",
              "--roz": "-0.07",
              "--sf": "0.56",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.19",
              "--roz": "-0.1",
              "--sf": "0.66",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.11",
              "--roy": "0.01",
              "--roz": "-0.04",
              "--sf": "0.88",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.02",
              "--roy": "-0.16",
              "--roz": "0.13",
              "--sf": "0.97",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.09",
              "--roy": "0.2",
              "--roz": "-0.19",
              "--sf": "0.69",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0",
              "--roy": "0",
              "--roz": "0.02",
              "--sf": "0.77",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.07",
              "--roy": "-0.12",
              "--roz": "0.16",
              "--sf": "0.56",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.02",
              "--roy": "-0.07",
              "--roz": "0.15",
              "--sf": "0.69",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.2",
              "--roy": "-0.03",
              "--roz": "0.12",
              "--sf": "0.76",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.04",
              "--roy": "0.18",
              "--roz": "-0.12",
              "--sf": "1",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "-0.07",
              "--roz": "-0.1",
              "--sf": "0.61",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.01",
              "--roy": "0.08",
              "--roz": "-0.05",
              "--sf": "0.58",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.1",
              "--roy": "0.17",
              "--roz": "-0.13",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.06",
              "--roy": "0.14",
              "--roz": "0.06",
              "--sf": "0.98",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.12",
              "--roy": "0.13",
              "--roz": "0.2",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.13",
              "--roy": "0.05",
              "--roz": "0.18",
              "--sf": "0.9",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.17",
              "--roy": "0.18",
              "--roz": "0.17",
              "--sf": "0.59",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.16",
              "--roy": "0.06",
              "--roz": "0.02",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.18",
              "--roy": "-0.02",
              "--roz": "0.03",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.19",
              "--roy": "-0.07",
              "--roz": "-0.13",
              "--sf": "0.67",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.04",
              "--roy": "-0.05",
              "--roz": "-0.01",
              "--sf": "0.57",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.12",
              "--roy": "-0.02",
              "--roz": "0",
              "--sf": "0.71",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.05",
              "--roy": "-0.17",
              "--roz": "0.14",
              "--sf": "0.86",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.08",
              "--roy": "0.05",
              "--roz": "0.16",
              "--sf": "0.83",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.03",
              "--roy": "-0.08",
              "--roz": "0.14",
              "--sf": "0.62",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.16",
              "--roy": "0.09",
              "--roz": "-0.11",
              "--sf": "0.64",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.05",
              "--roy": "0.03",
              "--roz": "0.15",
              "--sf": "0.87",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.07",
              "--roy": "-0.19",
              "--roz": "-0.13",
              "--sf": "0.62",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.05",
              "--roy": "-0.01",
              "--roz": "-0.14",
              "--sf": "0.97",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.2",
              "--roy": "0.18",
              "--roz": "0",
              "--sf": "0.94",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.01",
              "--roy": "-0.08",
              "--roz": "-0.08",
              "--sf": "0.55",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.03",
              "--roy": "0.05",
              "--roz": "-0.04",
              "--sf": "0.61",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.06",
              "--roy": "0.15",
              "--roz": "0.02",
              "--sf": "0.83",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.09",
              "--roy": "-0.19",
              "--roz": "-0.08",
              "--sf": "0.94",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.13",
              "--roy": "-0.17",
              "--roz": "0.2",
              "--sf": "0.67",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.05",
              "--roy": "0.12",
              "--roz": "0.18",
              "--sf": "0.88",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.18",
              "--roy": "-0.18",
              "--roz": "-0.04",
              "--sf": "0.92",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.02",
              "--roy": "0.18",
              "--roz": "0.01",
              "--sf": "0.91",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.08",
              "--roy": "0.07",
              "--roz": "0",
              "--sf": "1",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.01",
              "--roy": "-0.17",
              "--roz": "-0.02",
              "--sf": "0.56",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.09",
              "--roy": "-0.07",
              "--roz": "-0.01",
              "--sf": "0.69",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.05",
              "--roy": "0.09",
              "--roz": "0.03",
              "--sf": "0.5",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.13",
              "--roy": "-0.18",
              "--roz": "0.08",
              "--sf": "0.6",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.01",
              "--roy": "0.11",
              "--roz": "0.2",
              "--sf": "0.65",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.08",
              "--roy": "-0.06",
              "--roz": "-0.15",
              "--sf": "0.86",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.16",
              "--roy": "-0.19",
              "--roz": "-0.1",
              "--sf": "0.88",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.19",
              "--roz": "0.03",
              "--sf": "0.5",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.12",
              "--roz": "-0.13",
              "--sf": "0.54",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.15",
              "--roy": "-0.05",
              "--roz": "0.01",
              "--sf": "0.98",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.01",
              "--roy": "0.06",
              "--roz": "-0.08",
              "--sf": "0.59",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.04",
              "--roy": "0.06",
              "--roz": "0.03",
              "--sf": "0.7",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.16",
              "--roy": "-0.09",
              "--roz": "0.04",
              "--sf": "0.51",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.08",
              "--roy": "0.07",
              "--roz": "0.03",
              "--sf": "0.87",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "-0.17",
              "--roy": "-0.07",
              "--roz": "-0.13",
              "--sf": "0.66",
            }}
          />
          <div
            className="🌟"
            style={{
              "--rox": "0.01",
              "--roy": "-0.05",
              "--roz": "-0.17",
              "--sf": "0.77",
            }}
          />
        </div>
        ;
      </div>
    </>
  );
}

// const generateDynamicStyles = () => {
//   let styles = "";
//   for (let i = 1; i <= 100; i++) {
//     styles += `.🌟:nth-child(${100}n + ${i}) { --j: ${i - 1}; }\n`;
//   }
//   styles += `.🌟:nth-child(n + 1) { --i: 0; }\n`;
//   styles += `.🌟:nth-child(n + 101) { --i: 1; }\n`;
//   return styles;
// };
